import React, { useState } from "react";
import { Link } from "react-router-dom";
import i18n from "../../../i18n";
import thumb1 from "../../assets/img/portfolio/thumbs/portfolio-1.jpg";
import thumb2 from "../../assets/img/portfolio/thumbs/portfolio-2.jpg";
import thumb3 from "../../assets/img/portfolio/thumbs/portfolio-3.jpg";
import thumb4 from "../../assets/img/portfolio/thumbs/portfolio-4.png";
import fullImage1 from "../../assets/img/portfolio/portfolio-1.jpg"; // Full-size images
import fullImage2 from "../../assets/img/portfolio/portfolio-2.jpg";
import fullImage3 from "../../assets/img/portfolio/portfolio-3.jpg";
import fullImage4 from "../../assets/img/portfolio/portfolio-4.png";

const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Thumbnails and corresponding full-size images
  const thumbnails = [thumb1, thumb2, thumb3, thumb4];
  const fullImages = [fullImage1, fullImage2, fullImage3, fullImage4];

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % fullImages.length);
  };

  const showPrevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + fullImages.length) % fullImages.length
    );
  };

  const currentYear = new Date().getFullYear();

  return (
    <React.Fragment>
      <section className="footer-area">
        <div className="container">
          <div className="row">
            {/* Contact Info */}
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>Contact Info</h3>
                <ul className="footer-contact-info">
                  <li>
                    <i className="flaticon-phone-call"></i>
                    <span>Mon to Fri : 10:00AM - 06:00PM</span>
                    <a href={`tel:${i18n.t("web-tel")}`}>{i18n.t("web-tel")}</a>
                  </li>
                  <li>
                    <i className="flaticon-email"></i>
                    <span>Do You Have a Question?</span>
                    <a href={`mailto:${i18n.t("web-email")}`}>
                      {i18n.t("web-email")}
                    </a>
                  </li>
                  <li>
                    <i className="flaticon-social-media"></i>
                    <span>Socials Network</span>
                    <ul className="social">
                      <li>
                        <Link to="https://x.com/siadjeu2012">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.facebook.com/christian.siadjeu">
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.instagram.com/christian_siadjeu?igsh=MmxqaDN1cm1mdnV3">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.linkedin.com/in/christian-siadjeu-phd-dr-rer-nat-58019427/">
                          <i className="fab fa-linkedin"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-youtube"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.tiktok.com/@dnarna74?_t=8prQ8rp6F28&_r=1">
                          <i className="fab fa-tiktok"></i>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

            {/* Quick Links */}
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget pl-5">
                <h3>Quick Links</h3>
                <ul className="footer-quick-links">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* Instagram Gallery */}
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget pl-5">
                <h3>Portfolio</h3>
                <ul className="footer-instagram-post">
                  {thumbnails.map((thumbnail, index) => (
                    <li key={index}>
                      <a href="#" onClick={() => handleImageClick(index)}>
                        <img src={thumbnail} alt={`portfolio-${index}`} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Modal for Image Preview */}
          {showModal && (
            <div
              className="modal show fade d-block"
              tabIndex="-1"
              onClick={closeModal}
              style={{ background: "rgba(0, 0, 0, 0.8)" }}
            >
              <div
                className="modal-dialog modal-lg"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close"
                      onClick={closeModal}
                    ></button>
                  </div>
                  <div className="modal-body text-center">
                    <img
                      src={fullImages[currentImageIndex]}
                      alt="modal preview"
                      className="img-fluid"
                    />
                    {/* Navigation Buttons */}
                    <button
                      className="btn btn-primary position-absolute top-50 start-0"
                      onClick={showPrevImage}
                    >
                      &#10094;
                    </button>
                    <button
                      className="btn btn-primary position-absolute top-50 end-0"
                      onClick={showNextImage}
                    >
                      &#10095;
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Copyright Area */}
          <div className="copyright-area">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-6 col-md-6">
                <p>
                  Copyright @{currentYear} Molbio. All rights reserved{" "}
                  <a href="#" target="_blank">
                    NG Group
                  </a>
                </p>
              </div>

              <div className="col-lg-6 col-sm-6 col-md-6">
                <ul>
                  <li>
                    <Link to="/terms-conditions">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Footer;
