import React, { Component } from 'react';
import { Link } from "react-router-dom";
import blogImage from "../../assets/img/blog-image/blog-image10-510x400.jpg";
import i18n from "../../../i18n";

class BlogCard extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="blog-area blog-ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-blog-post">
                                    <div className="post-image">
                                        <Link to="/blog/la-maitrise-de-la-biologie-moleculaire-est-un-enjeu-crucial-pour-le-developpement-de-lafrique">
                                            <a>
                                                <img src={blogImage} alt="image" />
                                            </a>
                                        </Link>
                                    </div>
                                    <div className="post-content">
                                        <div className="post-meta">
                                            <ul>
                                                <li>
                                                    By: 
                                                    <Link to="/christian-siadjeu">
                                                        <a>Christian Siadjeu</a>
                                                    </Link>
                                                </li>
                                                <li>Septembre 15, 2024</li>
                                            </ul>
                                        </div>
                                        <h3>
                                            <Link to="/blog/la-maitrise-de-la-biologie-moleculaire-est-un-enjeu-crucial-pour-le-developpement-de-lafrique">
                                                <a>{i18n.t('blog-title-1').substring(0, 53)}...</a>
                                            </Link>
                                        </h3>
                                        <p>{i18n.t('blog-paragraph-a-1').substring(0, 123)}...</p>

                                        <Link href="/blog/la-maitrise-de-la-biologie-moleculaire-est-un-enjeu-crucial-pour-le-developpement-de-lafrique">
                                            <a className="read-more-btn">
                                                Read More <i className="flaticon-right-arrow"></i>
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>                    

                            {/* Pagination 
                            <div className="col-lg-12 col-md-12">
                                <div className="pagination-area">
                                    <Link href="#">
                                        <a className="prev page-numbers">
                                            <i className="fas fa-angle-double-left"></i>
                                        </a>
                                    </Link>
                                    <Link href="#">
                                        <a className="page-numbers">1</a>
                                    </Link>
                                    <span className="page-numbers current" aria-current="page">2</span>
                                    <Link href="#">
                                        <a  className="page-numbers">3</a>
                                    </Link>
                                    <Link href="#">
                                        <a className="page-numbers">4</a>
                                    </Link>
                                    <Link href="#">
                                        <a className="next page-numbers">
                                            <i className="fas fa-angle-double-right"></i>
                                        </a>
                                    </Link>
                                </div>
                            </div> */}
                            {/* End Pagination  */}
                        </div>
                   </div>
                </div>
            </React.Fragment>
        );
    }
}

export default BlogCard;