import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../i18n";
import Meta from "../components/layout/Meta";
import profilPicture from "../assets/img/christian-siadjeu-profile.jpeg"
import Navbar from "../components/layout/Navbar";
import PageHeader from "../components/layout/PageHeader";
import { connect } from "react-redux";

function Profile({ user, isLoggedIn }) {

  return (
    <>
    <Meta title={i18n.t("profile")} />
    <Navbar/>
    <PageHeader 
      pageTitle={"Dr. Christian Siadjeu"}
      breadcrumbTextOne="Home" 
      breadcrumbUrl="/" 
      breadcrumbTextTwo={i18n.t('profile')} 
  />
     <div>
      <div className="container">

        <div className="row">
          <div className="col-md-4 col-xl-3">
            <div className="card mb-3">
              <div className="card-header">
                <h5 className="card-title mb-0">Profile Details</h5>
              </div>
              <div className="card-body text-center">
                <img
                  src={profilPicture}
                  alt="Marie Salter"
                  className="img-fluid rounded-circle mb-2"
                  width="128"
                  height="128"
                />
                <h4 className="card-title mb-0">Dr. Christian Siadjeu</h4>
                <div className="text-muted mb-2">Associate Researcher</div>

                <div>
                  <a className="btn btn-primary btn-sm" href="https://www.linkedin.com/in/christian-siadjeu-phd-dr-rer-nat-58019427/">
                    Follow
                  </a>
                  <Link to="/contact">
                  <a className="btn btn-primary btn-sm" >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-message-square"
                    >
                      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                    </svg>{" "}
                    Message
                  </a></Link>
                </div>
              </div>
              
{/*               <hr className="my-0" />
              
              <div className="card-body">
                <h5 className="h6 card-title">Skills</h5>
                <a href="#/" className="badge badge-primary mr-1 my-1">
                  HTML
                </a>
                <a href="#/" className="badge badge-primary mr-1 my-1">
                  JavaScript
                </a>
                <a href="#/" className="badge badge-primary mr-1 my-1">
                  Sass
                </a>
                <a href="#/" className="badge badge-primary mr-1 my-1">
                  Angular
                </a>
                <a href="#/" className="badge badge-primary mr-1 my-1">
                  Vue
                </a>
                <a href="#/" className="badge badge-primary mr-1 my-1">
                  React
                </a>
                <a href="#/" className="badge badge-primary mr-1 my-1">
                  Redux
                </a>
                <a href="#/" className="badge badge-primary mr-1 my-1">
                  UI
                </a>
                <a href="#/" className="badge badge-primary mr-1 my-1">
                  UX
                </a>
              </div> */}

              <hr className="my-0" />
              <div className="card-body">
                <h5 className="h6 card-title">About</h5>
                <ul className="list-unstyled mb-0">
                  <li className="mb-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-home feather-sm mr-1"
                    >
                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>{" "}
                    Lives in <a href="#/">Frankfort</a>
                  </li>

                  <li className="mb-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-briefcase feather-sm mr-1"
                    >
                      <rect
                        x="2"
                        y="7"
                        width="20"
                        height="14"
                        rx="2"
                        ry="2"
                      ></rect>
                      <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                    </svg>{" "}
                    Works at <a href="#/">Johannes Gutenberg University Mainz</a>
                  </li>
                  <li className="mb-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-map-pin feather-sm mr-1"
                    >
                      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                      <circle cx="12" cy="10" r="3"></circle>
                    </svg>{" "}
                    From <a href="#/">Frankfort</a>
                  </li>
                </ul>
              </div>
              <hr className="my-0" />
              <div className="card-body">
                <ul className="list-unstyled mb-0">
                  <li className="mb-1">
                    <svg
                      className="svg-inline--fa fa-twitter fa-w-16 fa-fw mr-1"
                      aria-hidden="true"
                      data-prefix="fab"
                      data-icon="twitter"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                      ></path>
                    </svg>
                    <a href="https://x.com/siadjeu2012">Twitter</a>
                  </li>
                  <li className="mb-1">
                    <svg
                      className="svg-inline--fa fa-facebook fa-w-14 fa-fw mr-1"
                      aria-hidden="true"
                      data-prefix="fab"
                      data-icon="facebook"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M448 56.7v398.5c0 13.7-11.1 24.7-24.7 24.7H309.1V306.5h58.2l8.7-67.6h-67v-43.2c0-19.6 5.4-32.9 33.5-32.9h35.8v-60.5c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9h-58.4v67.6h58.4V480H24.7C11.1 480 0 468.9 0 455.3V56.7C0 43.1 11.1 32 24.7 32h398.5c13.7 0 24.8 11.1 24.8 24.7z"
                      ></path>
                    </svg>
                    <a href="https://www.facebook.com/christian.siadjeu">Facebook</a>
                  </li>
                  <li className="mb-1">
                    <svg
                      className="svg-inline--fa fa-instagram fa-w-14 fa-fw mr-1"
                      aria-hidden="true"
                      data-prefix="fab"
                      data-icon="instagram"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                      ></path>
                    </svg>
                    <a href="https://www.instagram.com/christian_siadjeu?igsh=MmxqaDN1cm1mdnV3">Instagram</a>
                  </li>
                  <li className="mb-1">
                    <svg
                      className="svg-inline--fa fa-linkedin fa-w-14 fa-fw mr-1"
                      aria-hidden="true"
                      data-prefix="fab"
                      data-icon="linkedin"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                      ></path>
                    </svg>
                    <a href="https://www.linkedin.com/in/christian-siadjeu-phd-dr-rer-nat-58019427/">LinkedIn</a>
                  </li>
                </ul> 
              </div>
            </div>
          </div>

          <div className="col-md-8 col-xl-9">
            <div className="card">
              <div className="card-header">
                <div className="card-actions float-right">
                  <div className="dropdown show">
                    <a href="#/" data-toggle="dropdown" data-display="static">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-more-horizontal align-middle"
                      >
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="19" cy="12" r="1"></circle>
                        <circle cx="5" cy="12" r="1"></circle>
                      </svg>
                    </a>

                    <div className="dropdown-menu dropdown-menu-right">
                      <a className="dropdown-item" href="#/">
                        Action
                      </a>
                      <a className="dropdown-item" href="#/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="#/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <h5 className="card-title mb-0">Activities</h5>
              </div>
              <div className="card-body h-100">
                <div className="media">
                  <img
                    src={profilPicture}
                    width="36"
                    height="36"
                    className="rounded-circle mr-2"
                    alt="Kathy Davis"
                  />
                  <div className="media-body">
                    <small className="float-right text-navy"></small>
                    <strong>Christian Siadjeu</strong> launch this website{" "}
                    <strong>cs-molbio.com</strong>
                    <br />
                    <small className="text-muted">29/09/2024 4 am</small>
                    <br />
                  </div>
                </div>

                <hr />
                <a href="#/" className="btn btn-primary btn-block">
                  Load more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.show.retrieved, // Adjust the state mapping based on your actual state structure
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps)(Profile);
