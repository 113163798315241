import React, { useState, useEffect } from "react";
import { Link, useLocation  } from 'react-router-dom';
import { connect } from "react-redux";
import i18n from "../../../i18n";

const NavbarMenu = ({products}) => {

    const [currentPath, setCurrentPath] = useState("");
    const location = useLocation();
    // console.log(router.asPath)
  
    useEffect(() => {
      setCurrentPath(location.pathname);
    }, [location]);

  // Navbar
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const elementId = document.getElementById("navbar");
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <>
              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link
                      to="/"
                      className="nav-link"
                      onClick={(e) => e.preventDefault()}
                    >
                        {i18n.t('home')} 
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/about"
                      className="nav-link"
                      onClick={(e) => e.preventDefault()}
                    >
                        {i18n.t('about')}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/publications"
                      className="nav-link"
                      onClick={(e) => e.preventDefault()}
                    >
                        {i18n.t('publications')} <i className="fas fa-chevron-down"></i>
                    </Link>

                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          to="/articles"
                          className={`nav-link ${
                            currentPath == "/articles" && "active"
                          }`}
                        >
                            {i18n.t('articles')}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/books"
                          className={`nav-link ${
                            currentPath == "/books" && "active"
                          }`}
                        >
                          {i18n.t('books')}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/media"
                          className={`nav-link ${
                            currentPath == "/media" && "active"
                          }`}
                        >
                          {i18n.t('media')}
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/bio-informatics"
                      className="nav-link"
                      onClick={(e) => e.preventDefault()}
                    >
                    {i18n.t('bioinformatics')}<i className="fas fa-chevron-down"></i>
                    </Link>

                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          to="/tools"
                          className={`nav-link ${
                            currentPath == "/tools" && "active"
                          }`}
                        >
                        {i18n.t('tools')}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/databases"
                          className={`nav-link ${
                            currentPath == "/databases" && "active"
                          }`}
                        >
                        {i18n.t('databases')}
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/events-conferences"
                      className="nav-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      {i18n.t('events-conferences')}<i className="fas fa-chevron-down"></i>
                    </Link>

                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          to="/conferences"
                          className={`nav-link ${
                            currentPath == "/conferences" && "active"
                          }`}
                        >
                        {i18n.t('conferences')}  
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/workshops-seminars"
                          className={`nav-link ${
                            currentPath == "/workshops-seminars" &&
                            "active"
                          }`}
                        >
                          {i18n.t('workshops-seminars')}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/contact"
                      className={`nav-link ${
                        currentPath == "/contact" && "active"
                      }`}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
    </>
  );
};

const mapStateToProps = (state) => {
    return {
      products: state.auth.addedItems,
      sidebarModal: false
    };
  };
  
  export default connect(mapStateToProps)(NavbarMenu);