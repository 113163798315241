import React from "react";
import { Link  } from 'react-router-dom';
import i18n from "../../../i18n";

const FeaturedServices = () => {
  return (
    <>
      <div className="featured-services-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">{i18n.t("our-service")}</span>
            <h2>{i18n.t("our-service-sub-title")}</h2>
            <p>
              {i18n.t("our-service-description")}
            </p>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-featured-box">
                <div className="icon">
                  <i className="flaticon-analytics"></i>
                </div>

                <h3>{i18n.t("service-1-title")}</h3>
                <p>
                  {i18n.t("service-1-description")}
                </p>

                <Link to="#" className="default-btn">
                {i18n.t("read-more")} <span></span>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-featured-box">
                <div className="icon color-facd60">
                  <i className="flaticon-achievement"></i>
                </div>

                <h3>{i18n.t("service-2-title")}</h3>
                <p>
                  {i18n.t("service-2-description")}
                </p>

                <Link to="#" className="default-btn">
                {i18n.t("read-more")} <span></span>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-featured-box">
                <div className="icon color-1ac0c6">
                  <i className="flaticon-research"></i>
                </div>

                <h3>{i18n.t("service-3-title")}</h3>
                <p>
                  {i18n.t("service-3-description")}
                </p>

                <Link to="#" className="default-btn">
                {i18n.t("read-more")} <span></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedServices;
