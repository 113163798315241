import React, { Component } from "react";
//import Navbar from '../components/Layout/Navbar';
import PageHeader from "../components/layout/PageHeader";
import Navbar from "../components/layout/Navbar";

class PrivacyPolicy extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <PageHeader
          pageTitle="Privacy Policy"
          breadcrumbTextOne="Home"
          breadcrumbUrl="/"
          breadcrumbTextTwo="PrivacyPolicy"
        />

        <div>
          <h3>Privacy Policy</h3>
          <p>Last Updated: Sep 30 2024</p>

          <p>
            Thank you for visiting the Privacy Policy of CS Molbio. This Privacy
            Policy explains how CS Molbio (collectively, “CS Molbio”, “we”,
            “us”, or “our”) collects, uses, and shares information about you
            (“you”, “yours” or “user”) when you access or use our websites
            (“Services”).
          </p>
          <p>
            Users are responsible for any third-party data they provide or share
            through the Services and confirm that they have the third-party's
            consent to provide such data to us.
          </p>
          <br />

          <h3>Information We Collect</h3>
          <p>
            We may collect and combine information about you when you access or
            use the Services, including:
          </p>
          <p>
            <strong>Contact Information</strong>: such as:
          </p>
          <ul>
            <li>
              <p>first and last name</p>
            </li>
            <li>
              <p>email address</p>
            </li>
            <li>
              <p>phone number</p>
            </li>
          </ul>
          <p>
            <strong>Log and Usage Information: </strong>such as browser type you
            use, hardware model, operating system, IP address, unique device
            identifiers, access times, pages viewed, links clicked, and browsing
            behavior such as time spent, what site you came from, what site you
            visit when you leave us, and browsing behavior.
          </p>

          <br />

          <h3>Sources of information and Tracking Technologies</h3>

          <p>
            We collect information that you directly provide to us, such as when
            you submit a form or send us a message with your information.
          </p>
          <p>
            We may also receive information about you from our partners,
            including but not limited to distribution partners, data services,
            and marketing firms. We may combine this information with other
            information we collect from or about you. In these cases, our
            Privacy Policy governs the handling of the combined information.
          </p>
          <p>
            We and our partners may collect the information noted in this
            privacy policy using cookies, web beacons, pixels and other similar
            technologies. These technologies are used for authentication, to
            store your preferences or progress, for analytics, and for
            advertising and analytics. Cookies are small text files stored on
            the User’s computer. Users can set their browser to reject cookies
            altogether, to reject our cookies in particular, or to delete
            cookies. However, this may cause some or all of our Services not to
            function on such user’s computer or device.
          </p>
          <br />
          <h3>How We Use Your Information</h3>
          <p>
            We use information we collect about you to provide, maintain, and
            improve our Services and other interactions we have with you. For
            example, we use the information collected to:
          </p>
          <ul>
            <li>
              <p>Facilitate and improve our online experience;</p>
            </li>
            <li>
              <p>
                Provide and deliver products and services, perform
                authentication, process transactions and returns, and send you
                related information, including confirmations, receipts,
                invoices, customer experience surveys, and product or
                Services-related notices;
              </p>
            </li>
            <li>
              <p>Process and deliver promotions;</p>
            </li>
            <li>
              <p>
                Respond to your comments and questions and provide customer
                service;
              </p>
            </li>
            <li>
              <p>
                If you have indicated to us that you wish to receive
                notifications or promotional messages;
              </p>
            </li>
            <li>
              <p>
                Detect, investigate and prevent fraudulent transactions and
                other illegal activities and protect our rights and property and
                others;
              </p>
            </li>
            <li>
              <p>Comply with our legal and financial obligations;</p>
            </li>
            <li>
              <p>Monitor and analyze trends, usage, and activities;</p>
            </li>
            <li>
              <p>
                Provide and allow our partners to provide advertising and
                marketing targeted toward your interests.
              </p>
            </li>
          </ul>
          <br />
          <h3>How We May Share Information</h3>
          <p>
            We may share your Personal Information in the following situations:
          </p>
          <ul>
            <li>
              <p>
                <strong>Third Party Services Providers. </strong>We may share
                data with service providers, vendors, contractors, or agents who
                complete transactions or perform services on our behalf, such as
                those that assist us with our business and internal operations
                like shipping and delivery, payment processing, fraud
                prevention, customer service, gift cards, experiences,
                personalization, marketing, and advertising;
              </p>
            </li>
            <li>
              <p>
                <strong>Change in Business. </strong>We may share data in
                connection with a corporate business transaction, such as a
                merger or acquisition of all or a portion of our business to
                another company, joint venture, corporate reorganization,
                insolvency or bankruptcy, financing or sale of company assets;
              </p>
            </li>
            <li>
              <p>
                <strong>To Comply with Law. </strong>We may share data to
                facilitate legal process from lawful requests by public
                authorities, including to meet national security or law
                enforcement demands as permitted by law.
              </p>
            </li>
            <li>
              <p>
                <strong>With Your Consent. </strong>We may share data with third
                parties when we have your consent.
              </p>
            </li>
            <li>
              <p>
                <strong>With Advertising and Analytics Partners. </strong>See
                the section entitled “Advertising and Analytics” below.
              </p>
            </li>
          </ul>
          <br />
          <h3>Advertising and Analytics</h3>
          <p></p>
          <p>
            We use advertising and analytics technologies to better understand
            your online activity on our Services to provide personalized
            products and services that may interest you.
          </p>
          <p>
            We may allow third-party companies, including ad networks, to serve
            advertisements, provide other advertising services and/or collect
            certain information when you visit our website. Third-party
            companies may use pseudonymized personal data (e.g., click stream
            information, browser type, time and date, subject of advertisements
            clicked or scrolled over) during your visit to this website in order
            to provide advertisements about goods and services likely to be of
            interest to you, on this website and others. To learn more about
            Interest-Based Advertising or to opt-out of this type of
            advertising, you can visit{" "}
            <a href="http://optout.aboutads.info/?c=2&amp;lang=EN">
              AboutAds.info/choices
            </a>{" "}
            or{" "}
            <a href="http://optout.networkadvertising.org/?c=1">
              www.networkadvertising.org/choices
            </a>
            .
          </p>
          <p>
            Some third-party companies may also use non-cookie technologies,
            such as statistical IDs. Please keep in mind that your web browser
            may not permit you to block the use of these non-cookie
            technologies, and those browser settings that block cookies may have
            no effect on such techniques. If the third-party company uses the
            non-cookie technologies for interest-based advertising, you can opt
            out at{" "}
            <a href="http://optout.networkadvertising.org/?c=1">
              www.networkadvertising.org/choices
            </a>
            . Please note the industry opt out only applies to use for
            interest-based advertising and may not apply to use for analytics or
            attribution.
          </p>
          <p>
            Some websites have “do not track” features that allow you to tell a
            website not to track you. These features are not all uniform. We do
            not currently respond to those signals.
          </p>
          <p></p>
          <br />
          <h3>Google Analytics</h3>
          <p>
            We use Google Analytics, an analytics service provided by Google
            LLC. We use this service to help analyze how users use the Service,
            with a view to analyzing usage across devices and offering
            improvements for all users. To learn more about Google Analytics,
            please visit their{" "}
            <a href="https://support.google.com/analytics/answer/6004245#zippy=%2Cour-privacy-policy">
              Privacy Policy
            </a>
            . To opt-out of this feature by installing the Google Analytics
            Opt-out Browser Add-on, please click{" "}
            <a href="https://tools.google.com/dlpage/gaoptout?hl=en">here</a>.
          </p>
          <br />
          <h3>Ads on the Service</h3>
          <p>
            We may use third-party advertising companies to serve content and
            advertisements when you visit our website. To opt-out of
            interest-based advertising, please see the section entitled
            “Advertising and Analytics” above.
          </p>
          <br />
          <h3>Data Security</h3>
          <p>
            We implement commercially reasonable security measures designed to
            protect your information. Despite our best efforts, however, no
            security measures are completely impenetrable.
          </p>
          <br />
          <h3>Data Retention</h3>
          <p>
            We store the information we collect about you for as long as
            necessary for the purpose(s) for which we collected it or for other
            legitimate business purposes, including to meet our legal,
            regulatory, or other compliance obligations.
          </p>
          <br />

          <h3>EU Privacy Rights</h3>

          <p>
            Individuals located in certain countries, including the European
            Economic Area (EEA) and the United Kingdom, have certain statutory
            rights under the General Data Protection Regulation (GDPR) in
            relation to their personal data.
          </p>
          <p>
            To the extent information we collect is associated with an
            identified or identifiable natural person and is protected as
            personal data under GDPR, it is referred to in this Privacy Policy
            as “Personal Data”.
          </p>
          <p>
            <strong>Data Subject Access Requests</strong>
          </p>
          <p>
            Subject to any exemptions provided by law, you may have the right to
            request:
          </p>
          <ul>
            <li>
              <p>a copy of the Personal Data we hold about you;</p>
            </li>
            <li>
              <p>to correct the Personal Data we hold about you;</p>
            </li>
            <li>
              <p>to delete your Account or Personal Data;</p>
            </li>
            <li>
              <p>
                to object to processing of your Personal Data for certain
                purposes;
              </p>
            </li>
          </ul>

          <p>
            To access your privacy rights, send us an email at
            siadjeu2012@gmail.com.
          </p>
          <p>
            We will generally process requests within one month. We may need to
            request specific information from you to help us confirm your
            identity and/or the jurisdiction in which you reside. If your
            request is complicated or if you have made a large number of
            requests, it may take us longer. We will let you know if we need
            longer than one month to respond.
          </p>
          <p>
            <strong>Legal Bases For Processing Personal Data</strong>
          </p>
          <p>
            We may process your Personal Data under applicable data protection
            law on the following legal grounds:
          </p>
          <ul>
            <li>
              <p>
                <strong>Contractual Necessity: </strong>we may process your
                Personal Data to enter into or perform a contract with you.
              </p>
            </li>
            <li>
              <p>
                <strong>Consent: </strong>where you have provided consent to
                process your Personal Data. You may withdraw your consent at any
                time.
              </p>
            </li>
            <li>
              <p>
                <strong>Legitimate interest: </strong>we process your Personal
                Data to provide our Services to you such as to provide our
                online user experience, communicate with you, provide customer
                service, market, analyze and improve our business, and to
                protect our Services.
              </p>
            </li>
          </ul>
          <br />
          <h3>Age Limitations</h3>
          <p>
            Our Service is intended for adults ages 18 years and above. We do
            not knowingly collect personally identifiable information from
            children. If you are a parent or legal guardian and think your child
            under 13 has given us information, please email or write to us at
            the address listed at the end of this Privacy Policy. Please mark
            your inquiries “COPPA Information Request.”
          </p>
          <br />
          <h3>Changes to this Privacy Policy</h3>
          <p>
            CS Molbio may change this Privacy Policy from time to time. We
            encourage you to visit this page to stay informed. If the changes
            are material, we may provide you additional notice to your email
            address or through our Services. Your continued use of the Services
            indicates your acceptance of the modified Privacy Policy.
          </p>
          <br />
          <h3>Newsletters</h3>
          <p>
            You can opt out of receiving our marketing emails and/or newsletters
            by contacting us as described under “Contact Us” below. We may still
            send you transactional messages, which include Services-related
            communications and responses to your questions.
          </p>
          <br />
          <h3>Storage of Information in the United States</h3>
          <p>
            Information we maintain may be stored both within and outside of the
            United States. If you live outside of the United States, you
            understand and agree that we may transfer your information to the
            United States, and that U.S. laws may not afford the same level of
            protection as those in your country.
          </p>
          <br />
          <h3>Contact Us</h3>
          <p>
            If you have questions, comments, or concerns about this Privacy
            Policy, you may contact us at:
          </p>
          <ul>
            <li>
              <p>https://cs-molbio.com</p>
            </li>
            <li>
              <p>siadjeu2012@gmail.com</p>
            </li>
            <li>
              <p>+49 172 9498930</p>
            </li>
          </ul>
          <br />
        </div>
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;
