import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { register } from "../../redux/actions/register";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt, faUser, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const Register = (props) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName:'',
    mobilePhone:'',
    terms:false,
    newsletter:false
  });

  const [passwordMatch, setPasswordMatch] = useState(true);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
      if (formData.terms) {
        props.launchOverlay(true);
        props.register(formData);
      }
    }
  };

  useEffect(() => {

    return () => {
      props.registerComplete();
    };
  }, []);

  /*const handleSubmit = (e) => {
    e.preventDefault();
    if(passwordMatch)
    dispatch(register(formData));
  };*/

    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row
              className="justify-content-center form-bg-image"
              //</Container>style={{ backgroundImage: `url(${BgImage})` }}
            >
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                {props.isRegister && formData.email? (
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Check your email</h3>

                    An email has been sent to you at <strong>{formData.email}</strong> . Contains the link for
                                activate your account.

                                <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      <Card.Link as={Link} to={"/login"} className="fw-bold">
                        {` Login page `}
                      </Card.Link>
                    </span>
                  </div>


                  </div>
                  </div>) : (
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Create an account</h3>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal text-info">
                      Already have an account? {" "}
                      <Card.Link as={Link} to={"/login"} className="fw-bold">
                        {` Login here `}
                      </Card.Link>
                    </span>
                  </div>

                  <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="firstName" className="mb-4">
                      <Form.Label>First Name</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUser} />
                        </InputGroup.Text>
                        <Form.Control
                          autoFocus
                          required
                          name="firstName"
                          placeholder="John"
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group id="lastName" className="mb-4">
                      <Form.Label>Last Name</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUser} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          name="lastName"
                          placeholder="Doe"
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group id="email" className="mb-4">
                      <Form.Label>Your Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="email"
                          name="email"
                          placeholder="example@company.com"
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          name="password"
                          placeholder="Password"
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="confirmPassword" className="mb-4">
                      <Form.Label>Confirm Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    {!passwordMatch && (
                      <p className="text-danger">Passwords do not match.</p>
                    )}

                    <Form.Group id="mobilePhone" className="mb-4">
                      <Form.Label>Mobile Phone</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faPhoneAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          name="mobilePhone"
                          placeholder="122345"
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </Form.Group>

                    <FormCheck type="checkbox" className="d-flex mb-4">
                      <FormCheck.Input 
                      onChange={handleInputChange} 
                      id="newsletter"
                      name="newsletter" 
                      className="me-2"
                      />
                      <FormCheck.Label htmlFor="newsletter">
                        Subscribe to our newsletter
                      </FormCheck.Label>
                    </FormCheck>

                    <FormCheck type="checkbox" className="d-flex mb-4">
                      <FormCheck.Input 
                      onChange={handleInputChange}
                      required 
                      id="terms" 
                      name="terms" 
                      className="me-2" />
                      <FormCheck.Label htmlFor="terms">
                        I agree to the{" "}
                        <Card.Link>terms and conditions</Card.Link>
                      </FormCheck.Label>
                    </FormCheck>

                    <Button variant="primary" type="submit" className="w-100">
                      Sign up
                    </Button>
                  </Form>

{/*                  <div className="mt-3 mb-4 text-center">
                    <span className="fw-normal">or</span>
                  </div>

                   <div className="d-flex justify-content-center my-4">
                    <Button
                      variant="outline-light"
                      className="btn-icon-only btn-pill text-facebook me-2"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </Button>
                    <Button
                      variant="outline-light"
                      className="btn-icon-only btn-pill text-twitter me-2"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </Button>
                    <Button
                      variant="outline-light"
                      className="btn-icon-only btn-pil text-dark"
                    >
                      <FontAwesomeIcon icon={faGithub} />
                    </Button>
                  </div> */}

                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal text-info">
                      Already have an account? {" "}
                      <Card.Link as={Link} to={"/login"} className="fw-bold">
                        {` Login here `}
                      </Card.Link>
                    </span>
                  </div>
                  
                </div>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }

  Register.propTypes = {
    register: PropTypes.func,
    isRegister: PropTypes.bool,
    registerComplete: PropTypes.func,
};

//export default Register;
const mapStateToProps = (state) => ({
  ...state,
  isRegister: state.auth.isRegister,
});

const mapDispatchToProps = dispatch => ({
  register: (user) => dispatch(register(user)),
  registerComplete: () => dispatch({
    type: "REGISTER_SUCCESS",
    payload: false,
  }),
  launchOverlay: (value) =>dispatch({
    type: "OVERLAY_LOADING",
    payload: value,
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);