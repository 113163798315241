import React, { useState, useEffect } from "react";
import { Link, useLocation  } from 'react-router-dom';
import { connect } from "react-redux";
import SidebarModal from "./SidebarModal";
import i18n from "../../../i18n";

function NavbarTwo ({ products })  {
  const [currentPath, setCurrentPath] = useState("");
  const location = useLocation();
  // console.log(router.asPath)

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  // Sidebar Modal
  const [sidebarModal, setSidebarModal] = useState(false);
  const toggleModal = () => {
    setSidebarModal(!sidebarModal);
  };

  // Search Form
  const [searchForm, setSearchForm] = useState(false);
  const handleSearchForm = () => {
    setSearchForm((prevState) => !prevState);
  };

  // Navbar
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const handleScroll = () => {
      const elementId = document.getElementById("navbar");
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <>
      <div id="navbar" className="navbar-area navbar-area-two">
        <div className="main-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/" className="navbar-brand">
              <img
                  src={require("../../assets/img/logo.png")}
                  alt="logo"
                  style={{ width: "38px", height: "38px" }} 
                /> 
                Molbio
              </Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link
                      to="/"
                      className={`nav-link ${
                       ( currentPath === "" || currentPath === "/")? "active":""
                      }`}
                    >
                        {i18n.t('home')} 
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/about"
                      className="nav-link"
                    >
                        {i18n.t('about')}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/publications"
                      className="nav-link"
                      onClick={(e) => e.preventDefault()}
                    >
                        {i18n.t('publications')} <i className="fas fa-chevron-down"></i>
                    </Link>

                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          to="/articles"
                          className={`nav-link ${
                            currentPath == "/articles" && "active"
                          }`}
                        >
                            {i18n.t('articles')}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/books"
                          className={`nav-link ${
                            currentPath == "/books" && "active"
                          }`}
                        >
                          {i18n.t('books')}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/media"
                          className={`nav-link ${
                            currentPath == "/media" && "active"
                          }`}
                        >
                          {i18n.t('media')}
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/bio-informatics"
                      className="nav-link"
                      onClick={(e) => e.preventDefault()}
                    >
                    {i18n.t('bioinformatics')}<i className="fas fa-chevron-down"></i>
                    </Link>

                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          to="/tools"
                          className={`nav-link ${
                            currentPath == "/tools" && "active"
                          }`}
                        >
                        {i18n.t('tools')}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/databases"
                          className={`nav-link ${
                            currentPath == "/databases" && "active"
                          }`}
                        >
                        {i18n.t('databases')}
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/events-conferences"
                      className={`nav-link ${
                        currentPath === "/events-conferences" && "active"
                      }`}
                    >
                      {i18n.t('events-conferences')}
                    </Link>
                  </li>

                  {/*<li className="nav-item">
                    <Link
                      to="/blog"
                      className={`nav-link ${
                        currentPath == "/blog" && "active"
                      }`}
                    >
                      Blog
                    </Link>
                  </li>*/}

                  <li className="nav-item">
                    <Link
                      to="/contact"
                      className={`nav-link ${
                        currentPath == "/contact" && "active"
                      }`}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="others-options">
                <div className="burger-menu" onClick={toggleModal}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      {/* Right Sidebar Modal */}
      <SidebarModal
        onClick={toggleModal}
        active={sidebarModal ? "active" : ""}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.auth.addedItems,
  };
};

export default connect(mapStateToProps)(NavbarTwo);
