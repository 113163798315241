import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import i18n from "../../../i18n";

const AboutArea = () => {
  // To open the lightbox change the value of the "toggler" prop.
  const [toggler, setToggler] = useState(false);

  return (
    <>
      <FsLightbox
        toggler={toggler}
        sources={["https://www.youtube.com/watch?v=bk7McNUjWgw"]}
      />

      <div className="about-area-two ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="about-image">
                <img
                  src={require("../../assets/img/about-img2.jpg")}
                  alt="image"
                  className="rounded-10"
                  width={500}
                  height={750}
                />

                <div className="solution-video">
                  <div
                    onClick={() => setToggler(!toggler)}
                    className="video-btn"
                  >
                    <i className="flaticon-play-button"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-7 col-md-12">
              <div className="about-content">
                <div className="section-title text-left">
                  <span className="sub-title">{i18n.t("about")}</span>
                  <h2>{i18n.t("")}</h2>
                  <p>
                    {i18n.t("about-team-description")}
                  </p>
                </div>
                
                <div className="about-text">
                  <h4>{i18n.t("who-we-are")}</h4>
                  <p>
                  {i18n.t('welcome-to-my-website')}
                  </p>
                </div>

                <div className="about-text">
                  <h4>{i18n.t("our-mission-title")}</h4>
                  <p>
                  {i18n.t("our-mission-description")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutArea;
