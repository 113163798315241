import React from "react";
import Navbar from "../components/layout/Navbar";
import PageHeader from "../components/layout/PageHeader";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
import i18n from "../../i18n";
import Meta from "../components/layout/Meta";

const Databases = () => {
  return (
    <>
      <Navbar />
      <Meta title={i18n.t("databases")} />
      <PageHeader
        pageTitle={i18n.t("databases")}
        breadcrumbTextOne="Home"
        breadcrumbUrl="/"
        breadcrumbTextTwo={i18n.t("databases")}
      />

      <div className="faq-area ptb-100 bg-f4f7fe">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg">
              <div className="faq-accordion">
                <Accordion allowZeroExpanded preExpanded={["a"]}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        NCBI: National Center for Biotechnology Information
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                        Houses a series of databases:
                        <ul>
                          <li>
                            <a
                              href="https://www.ncbi.nlm.nih.gov/"
                              target="_blank"
                            >
                              https://www.ncbi.nlm.nih.gov/
                            </a>
                          </li>
                        </ul>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="b">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Uniprot database
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                        Resource of protein sequence and functional information:
                        <ul>
                          <li>
                            <a href="https://www.uniprot.org/" target="_blank">
                              https://www.uniprot.org/
                            </a>
                          </li>
                        </ul>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="c">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        CGIR: Chloroplast Genome Information Resource
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                        <ul>
                          <li>
                            <a
                              href="https://ngdc.cncb.ac.cn/cgir/"
                              target="_blank"
                            >
                              https://ngdc.cncb.ac.cn/cgir/
                            </a>
                          </li>
                        </ul>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="d">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        PlantTFDB: Plant Transcription Factor Database:
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                        <ul>
                          <li>
                            <a
                              href=" https://planttfdb.gao-lab.org/"
                              target="_blank"
                            >
                              https://planttfdb.gao-lab.org/
                            </a>
                          </li>
                        </ul>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="e">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        RCSB Protein Data Bank: for protein 3D structure:
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                        <ul>
                          <li>
                            <a href="https://www.rcsb.org/" target="_blank">
                              https://www.rcsb.org/
                            </a>
                          </li>
                        </ul>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="f">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        The European Nucleotide Archive (ENA):
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                        A comprehensive record of the world’s nucleotide
                        sequencing information, covering raw sequencing data,
                        sequence assembly information and functional annotation:
                        <ul>
                          <li>
                            <a
                              href="https://www.ebi.ac.uk/ena/browser/home"
                              target="_blank"
                            >
                              https://www.ebi.ac.uk/ena/browser/home
                            </a>
                          </li>
                        </ul>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="g">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Genome online database
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                        <ul>
                          <li>
                            <a href="https://gold.jgi.doe.gov/" target="_blank">
                              https://gold.jgi.doe.gov/
                            </a>
                          </li>
                        </ul>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="e">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Reactome is a free, open-source, curated and
                        peer-reviewed pathway database
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                        <ul>
                          <li>
                            <a href="https://reactome.org/" target="_blank">
                              https://reactome.org/
                            </a>
                          </li>
                        </ul>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.addedItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default Databases;
