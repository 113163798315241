import React, { Component } from "react";
import { Link } from "react-router-dom";
import i18n from "../../../i18n";

export class SidebarModal extends Component {
  state = {
    modal: false,
  };

  closeModal = () => {
    this.props.onClick(this.state.modal);
  };

  render() {
    return (
      <React.Fragment>
        <div className={`sidebar-modal ${this.props.active}`}>
          <div className="sidebar-modal-inner">
            <div className="sidebar-about-area">
              <div className="title">
                <h2>{i18n.t("about")}</h2>
                <p>
                  {i18n.t("side-bar-description")}
                </p>
              </div>
            </div>

            <div className="sidebar-contact-area">
              <div className="contact-info">
                <div className="contact-info-content">
                  <h2>
                    <span className="main-color"><a href={`tel:${i18n.t("web-tel")}`}>{i18n.t('web-tel')}</a></span>
                    <span className="or">OR</span>
                    <span><a href={`mailto:${i18n.t("web-email")}`}>{i18n.t('web-email')}</a>
                    </span>
                  </h2>

                  <ul className="social">
                    <li>
                      <Link to="https://x.com/siadjeu2012">
                        <a target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link href="#">
                        <a target="_blank">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.facebook.com/christian.siadjeu">
                        <a target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.linkedin.com/in/christian-siadjeu-phd-dr-rer-nat-58019427/">
                        <a target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.instagram.com/christian_siadjeu?igsh=MmxqaDN1cm1mdnV3">
                        <a target="_blank">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="https://www.tiktok.com/@dnarna74?_t=8prQ8rp6F28&_r=1"
                        target="_blank"
                      >
                        <i className="fab fa-tiktok"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <span
              onClick={this.closeModal}
              className="close-btn sidebar-modal-close-btn"
            >
              <i className="flaticon-close"></i>
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SidebarModal;
