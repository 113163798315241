import React from "react";
import Navbar from "../components/layout/Navbar";
import PageHeader from "../components/layout/PageHeader";
import i18n from "../../i18n";
import Meta from "../components/layout/Meta";

const Articles = () => {

  return (
    <>
      <Navbar />
      <Meta title={i18n.t('articles')} />
      <PageHeader
        pageTitle={i18n.t('articles')}
        breadcrumbTextOne="Home"
        breadcrumbUrl="/"
        breadcrumbTextTwo={i18n.t('articles')}
      />

      <div style={{ padding: '20px', textAlign: 'center' }}>
        <h2>{i18n.t('Google Scholar Profile')}</h2>
        <p>{i18n.t('View the full list of publications on Google Scholar.')}</p>
        <a 
          href="https://scholar.google.com/citations?user=4NvBRToAAAAJ&hl=en" 
          target="_blank" 
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: '#007bff', fontSize: '18px' }}>
          {i18n.t('Click here to view the profile')}
        </a>
      </div>
    </>
  );
}

export default Articles;
