import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../../i18n";
import blogImage from "../../assets/img/blog-image/blog-image10-510x400.jpg";

const BlogSideBar = () => {
  return (
    <>
      <aside className="widget-area" id="secondary">
        {/* Search
        <div className="widget widget_search">
          <form className="search-form">
            <label>
              <input
                type="search"
                className="search-field"
                placeholder="Search..."
              />
            </label>
            <button type="submit">
              <i className="fas fa-search"></i>
            </button>
          </form>
        </div>
 */}
        {/* Popular Posts */}
        <div className="widget widget_posts_thumb">
          <h3 className="widget-title">Popular Posts</h3>


        </div>

        {/* Categories */}
        <div className="widget widget_categories">
          <h3 className="widget-title">Categories</h3>

          <ul>
            <li>
              <Link to="/blog">Biologie</Link>
            </li>
          </ul>
        </div>

        {/* Recent Posts */}
        <div className="widget widget_recent_entries">
          <h3 className="widget-title">Recent Posts</h3>

        </div>

        {/* Tags */}
        <div className="widget widget_tag_cloud">
          <h3 className="widget-title">Tags</h3>

          <div className="tagcloud">
            <Link to="/blog">
            Biologie <span className="tag-link-count"> (1)</span>
            </Link>
          </div>
        </div>
      </aside>
    </>
  );
};

export default BlogSideBar;
